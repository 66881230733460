var $viewButton = $('.phases .phase .view-button');
var $phases = $('.phases .phase');
var $phasesLength = $phases.length;
var $filterButton = $('.phases .phases-filter .phase-titles .phase-title');
var $clearAll = $('.phases .phases-filter .filter-top #clear-button');
var $titleCircle = $('.phases .phases-wrapper .phase .titles .title-phase span');
var $title = $('.phases .phases-wrapper .phase .titles .title-phase');
var $firstServices = $('.phases .phases-wrapper .phase .services .first-services');
var $lastServices = $('.phases .phases-wrapper .phase .services .last-services');
var $serviceElem = $('.phases .phase .services .service a');

$serviceElem.each(function () {
    var $bgColor = $(this).data('bg-color');
    var $textColor = $(this).data('text-color');
    if ($bgColor.length > 0) {
        $(this).find('svg path').css({
            'fill': $bgColor
        });
        $(this).hover(function () {
            $(this).css({
                'background-color': $bgColor,
                'color': $textColor
            });
            $(this).find('svg path').css({
                'fill': $textColor
            });
        }, function () {
            $(this).css({
                'background-color': '#F2F2F1',
                'color': '#000'
            });
            $(this).find('svg path').css({
                'fill': $bgColor
            });
        });
    }
});

if ($phasesLength > 0) {
    if ($(window).width() >= 768 && $(window).width() < 1024) {
        $(window).scroll(function () {
            for (var i = 0; i < $phasesLength; i++) {
                if (($(this).scrollTop() >= ($title.eq(i).offset().top - 16)) && ($(this).scrollTop() < ($('.phases').offset().top + $('.phases').outerHeight() - 80))) {
                    $titleCircle.eq(i).css({
                        'transform': 'translateY(' + ($(this).scrollTop() - $title.eq(i).offset().top + 16) + 'px)'
                    });
                }
                else {
                    $titleCircle.eq(i).css({
                        'transform': 'translateY(0)'
                    });
                }
            }
        });
    }
    else if ($(window).width() >= 1024) {
        $(window).scroll(function () {
            for (var i = 0; i < $phasesLength; i++) {
                if (($(this).scrollTop() >= ($title.eq(i).offset().top - 24)) && ($(this).scrollTop() < ($('.phases').offset().top + $('.phases').outerHeight() - 80))) {
                    $titleCircle.eq(i).css({
                        'transform': 'translateY(' + ($(this).scrollTop() - $title.eq(i).offset().top + 24) + 'px)'
                    });
                }
                else {
                    $titleCircle.eq(i).css({
                        'transform': 'translateY(0)'
                    });
                }
            }
        });
    }
}

if ($(window).width() < 1024) {
    for (var j = 0; j < $firstServices.length; j++) {
        var $service = $firstServices.eq(j).find('.service');
        var $servicesLength = $service.length;
        var $outerDivLeft = $('<div class="column-left"></div>');
        var $outerDivRight = $('<div class="column-right"></div>');
        var $wrapper = $('<div class="wrapper"></div>');
        var $columnWrapper = $('<div class="column-wrapper"></div>');
        for (var k = 0; k < $servicesLength; k++) {
            if (!$service.eq(0).hasClass('size-wide') && !$service.eq(0).next().hasClass('size-wide') && !$service.eq(0).next().next().hasClass('size-wide') && !$service.eq(0).next().next().next().hasClass('size-wide')) {
                $outerDivLeft.append($service.eq(0));
                $outerDivRight.append($service.eq(1));
                $outerDivLeft.append($service.eq(2));
                $outerDivRight.append($service.eq(3));
                $wrapper.append($service.eq(4));
                $service.eq(5).addClass('not-append');
                $wrapper.append($service.eq(5));
                $service.eq(6).addClass('not-append');
                $wrapper.append($service.eq(6));
                $phases.eq(j).find('.services').addClass('reverse');
            }
        }
        $columnWrapper.append($outerDivLeft);
        $columnWrapper.append($outerDivRight);
        $firstServices.eq(j).append($wrapper);
        $firstServices.eq(j).append($columnWrapper);
    }

    for (var j = 0; j < $lastServices.length; j++) {
        var $service = $lastServices.eq(j).children('.service');
        var $servicesLength = $service.length;
        var $outerDivLeft = $('<div class="column-left"></div>');
        var $outerDivRight = $('<div class="column-right"></div>');
        var service6 = '';
        var service7 = '';
        if ($firstServices.eq(j).parent('.services').hasClass('reverse')) {
            $service6 = $firstServices.eq(j).children('.wrapper').children('.service').eq(1);
            $service7 = $firstServices.eq(j).children('.wrapper').children('.service').eq(2);
        }
        else {
            $service6 = $firstServices.eq(j).children('.service').eq(5);
            $service7 = $firstServices.eq(j).children('.service').eq(6);
        }
        if ($service6.length > 0) {
            $outerDivLeft.append($service6);
        }
        if ($service7.length > 0) {
            $outerDivRight.append($service7);
        }
        if ($servicesLength > 0) {
            for (var k = 0; k < $servicesLength; k++) {
                if (k % 2 == 0) {
                    $outerDivLeft.append($service.eq(k));
                }
                else {
                    $outerDivRight.append($service.eq(k));
                }
            }
        }
        $lastServices.eq(j).append($outerDivLeft);
        $lastServices.eq(j).append($outerDivRight);
    }
}
else {
    for (var j = 0; j < $firstServices.length; j++) {
        $phases.eq(j).find('.first-services').children('.service').eq(5).addClass('second-to-last');
        $phases.eq(j).find('.first-services').children('.service').eq(6).addClass('last');
        var $service = $firstServices.eq(j).find('.service');
        var $servicesLength = $service.length;
        if ($servicesLength >= 6) {
            $phases.eq(j).find('.services').addClass('over-5');
        }
        var $wrapper = $('<div class="wrapper"></div>');
        var $columnWrapper = $('<div class="column-wrapper"></div>');
        var $outerDivLeft = $('<div class="column-left"></div>');
        var $outerDivRight = $('<div class="column-right"></div>');
        for (var k = 0; k < $servicesLength; k++) {
            if ($service.eq(k).prev().hasClass('size-wide') || $service.eq(k).prev().prev().hasClass('size-wide') || $service.eq(k).hasClass('size-wide')) {
                $service.eq(k).addClass('not-append');
            }
            else if (k == 0) {
                $service.eq(k).addClass('odd');
            }
            else if (k % 2 == 0) {
                $service.eq(k).addClass('odd');
            }
            else {
                $service.eq(k).addClass('even');
            }
        }
        for (var k = 0; k < $servicesLength; k++) {
            if ($service.eq(k).hasClass('odd') && (!$service.eq(k).hasClass('second-to-last')) && (!$service.eq(k).hasClass('last'))) {
                $outerDivLeft.append($service.eq(k));
            }
            else if ($service.eq(k).hasClass('even') && (!$service.eq(k).hasClass('second-to-last')) && (!$service.eq(k).hasClass('last'))) {
                $outerDivRight.append($service.eq(k));
            }
            else {
                $wrapper.append($service.eq(k));
            }
        }
        $columnWrapper.append($outerDivLeft);
        $columnWrapper.append($outerDivRight);
        $firstServices.eq(j).append($wrapper);
        $firstServices.eq(j).append($columnWrapper);
    }

    for (var j = 0; j < $lastServices.length; j++) {
        var $service = $lastServices.eq(j).children('.service');
        var $servicesLength = $service.length;
        var $outerDiv1 = $('<div class="column-1"></div>');
        var $outerDiv2 = $('<div class="column-2"></div>');
        var $outerDiv3 = $('<div class="column-3"></div>');
        var $outerDiv4 = $('<div class="column-4"></div>');
        if ($servicesLength > 0) {
            for (var k = 0; k < $servicesLength; k += 4) {
                $outerDiv1.append($service.eq(k));
            }
            for (var k = 1; k < $servicesLength; k += 4) {
                $outerDiv2.append($service.eq(k));
            }
            for (var k = 2; k < $servicesLength; k += 4) {
                $outerDiv3.append($service.eq(k));
            }
            for (var k = 3; k < $servicesLength; k += 4) {
                $outerDiv4.append($service.eq(k));
            }
        }
        $lastServices.eq(j).append($outerDiv1);
        $lastServices.eq(j).append($outerDiv2);
        $lastServices.eq(j).append($outerDiv3);
        $lastServices.eq(j).append($outerDiv4);
    }
}

var $servicesCount = '';
for (var i = 0; i <= $phasesLength; i++) {
    displayLess($phases.eq(i));
}

$viewButton.click(function (e) {
    e.preventDefault();
    if ($(this).hasClass('more')) {
        $(this).removeClass('show');
        $(this).parent().children('.less').addClass('show');
        $(this).parent().children('.services').addClass('more-services');
        $(this).parent().find('.service').addClass('display');
    }
    else if ($(this).hasClass('less')) {
        $(this).removeClass('show');
        $(this).parent().children('.more').addClass('show');
        $(this).parent().children('.services').removeClass('more-services');
        $(this).parent().find('.service').removeClass('display');
        displayLess($(this).parent());
    }
});

$filterButton.click(function () {
    var $classList = $(this).attr("class");
    var $classArr = $classList.split(/\s+/);
    if ($(this).hasClass('active')) {
        $(this).removeClass('active');
        for (var j = 0; j < $phasesLength; j++) {
            for (var i = 0; i < $classArr.length; i++) {
                if ($phases.eq(j).hasClass($classArr[i])) {
                    $phases.eq(j).removeClass('show');
                }
            }
        }
        if ($('.phases .phases-filter .phase-titles .phase-title.active').length === 0) {
            $phases.addClass('show');
        }
    }
    else {
        if ($('.phases .phases-filter .phase-titles .phase-title.active').length === 0) {
            $phases.removeClass('show');
        }
        $(this).addClass('active');
        for (var j = 0; j < $phasesLength; j++) {
            for (var i = 0; i < $classArr.length; i++) {
                if ($phases.eq(j).hasClass($classArr[i])) {
                    $phases.eq(j).addClass('show');
                }
            }
        }
    }
});

$clearAll.click(function () {
    $filterButton.removeClass('active');
    $phases.addClass('show');
});

function displayLess($phase) {
    var $servicesCount = $phase.find('.service').length;

    if ($(window).width() < 1024) {
        if ($phase.find('.services').hasClass('reverse')) {
            for (var j = 0; j < 7; j++) {
                $phase.find('.first-services').find('.service').eq(j).addClass('display');
            }
            $('.service.not-append').removeClass('display');
            $phase.children('.more').children('p').html('View all (+' + ($servicesCount - 5) + ')');
            if ($servicesCount <= 5) {
                $phase.children('.more').removeClass('show');
            }
        }
        else {
            for (var j = 0; j < 5; j++) {
                $phase.find('.first-services').find('.service').eq(j).addClass('display');
            }
            $phase.children('.more').children('p').html('View all (+' + ($servicesCount - 5) + ')');
            if ($servicesCount <= 5) {
                $phase.children('.more').removeClass('show');
            }
        }
    }
    else {
        for (var j = 0; j < 7; j++) {
            $phase.find('.wrapper').children('.service').eq(j).addClass('display');
            $phase.find('.column-left').children('.service').eq(j).addClass('display');
            $phase.find('.column-right').children('.service').eq(j).addClass('display');
        }
        $phase.children('.more').children('p').html('View all (+' + ($servicesCount - 7) + ')');
        if ($servicesCount <= 7) {
            $phase.children('.more').removeClass('show');
        }
    }
}
