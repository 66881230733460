
document.addEventListener('DOMContentLoaded', function () {
    const block = document.querySelector('.page-template-resource-guide');
    if(block) {
        //Toggle which tbody to show when button is clicked
        // Get all buttons and contents
        const buttons = document.querySelectorAll('.btn-group button');
        const contents = document.querySelectorAll('.phase-tbody');
        const contentsIntro = document.querySelectorAll('.phase-intro');
        const searchSection = document.querySelector('.intro-and-search')
        const tableTarget = document.querySelector('table');

        // Add click event listener to the button container
        document.querySelector('.btn-group').addEventListener('click', function (event) {
            if (event.target.tagName === 'BUTTON') {
                // Remove the "active" class from all buttons
                buttons.forEach(button => {
                    button.classList.remove('active');
                });


                event.target.classList.add('active');
                searchSection.style.display = 'flex';
                tableTarget.style.display = 'block';

                contents.forEach(content => {
                    content.style.display = 'none';
                    content.classList.remove('active-tbody');
                });

                contentsIntro.forEach(content => {
                    content.style.display = 'none';
                    content.classList.remove('active-tbody');
                });


                const contentId = event.target.dataset.tableName;
                const contentToShow = document.getElementById(contentId);
                const introToShow = document.getElementById(contentId +'-intro');
                console.log(introToShow)
                contentToShow.style.display = 'table-row-group';
                contentToShow.classList.add('active-tbody');
                introToShow.style.display = 'block';
                introToShow.classList.add('active-intro')
            }
        });


//modal and datafetching
// Function to open the modal
        function openPostModal() {
            const modal = document.getElementById('postModal');
            modal.style.display = 'block';

            // Close the modal when clicked outside of 'modal-content'
            window.addEventListener('click', closeModalOnClickOutside);
        }



// Add an event listener to elements with a specific class (e.g., 'open-modal-button')
        const triggerElements = document.querySelectorAll('.open-modal-button');
        triggerElements.forEach(triggerElement => {
            triggerElement.addEventListener('click', () => {
                //TODO: would it be better to fetch only the data needed instead of the entire post?
                // Fetch WordPress post content using AJAX
                const postId = triggerElement.dataset.postNum;


                const setLang = document.querySelector('.resource-guide-intro').dataset.setLanguage;

                fetch(`/wp-json/wp/v2/reusables?slug=${postId}`)
                    .then(response => response.json())
                    .then(data => {
                        // Populate the modal with the post content
                        console.log(data)
                        //SetLang can be either norwegian_content or english_content this is from DOM and is set on the page
                        const setLang = document.querySelector('.resource-guide-intro').dataset.setLanguage
                        let content = data[0].acf[setLang];


                        const regex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"[^>]*>(.*?)<\/a>/g;
                        content = content.replace(
                            regex,
                            '<a href="$1" target="_blank">$2</a>'
                        );
                        const postContent = document.getElementById('postContent');
                        postContent.innerHTML = content;


                        openPostModal(); // Open the modal after content is loaded
                    })
                    .catch(error => {
                        console.error('Error fetching post:', error);
                    });
            });
        });

// Close the modal when the close button is clicked
        const closeModalButton = document.querySelector('.close-modal');
        closeModalButton.addEventListener('click', () => {
            closePostModal();
        });

// Function to close the modal
        function closePostModal() {
            const modal = document.getElementById('postModal');
            modal.style.display = 'none';
            // Remove the event listener to prevent multiple listeners
            window.removeEventListener('click', closeModalOnClickOutside);
        }

// Function to close the modal when clicked outside of 'modal-content'
        function closeModalOnClickOutside(event) {
            const modalContent = document.querySelector('.modal-content');
            if (!modalContent.contains(event.target)) {
                closePostModal();
            }
        }




    // Get the input element and table with class "tbody-active"
    const input = document.getElementById('searchInput');
    const table = document.querySelector('table');
    const rows = table.querySelectorAll('.active-tbody tr');

// Add an event listener to the input element for filtering
    input.addEventListener('input', function () {
        let subElements = document.querySelectorAll('.active-tbody  .sub-element');
        subElements.forEach(subElement => {
            subElement.classList.remove('hidden');
        });
        const filter = input.value.toLowerCase();
        let showTableMainElement = false;
        let lastTableMainElement = null;

        rows.forEach((row, index) => {
            const cells = row.querySelectorAll('.sub-element-title');
            let shouldHide = true;

            for (let j = 0; j < cells.length; j++) {
                const cell = cells[j];
                if (cell) {
                    const text = cell.textContent.toLowerCase();
                    if (text.includes(filter)) {
                        shouldHide = false;

                        if (row.classList.contains('table-main-element')) {
                            showTableMainElement = true;
                        }

                        // Check for a parent element with data attribute "data-index-number"
                        const parentWithIndex = cell.closest('.sub-element[data-index-number]');
                        if (parentWithIndex !== null) {
                            // Find the "table-main-element" row with the same data index
                            const dataIndexNumber = parentWithIndex.getAttribute('data-index-number');
                            const mainElements = document.querySelectorAll('.table-main-element[data-index-number="' + dataIndexNumber + '"]');
                            mainElements.forEach(mainElement => (mainElement.style.display = ''));

                            lastTableMainElement = row;
                        }

                        break;
                    }
                }
            }

            if (row.classList.contains('table-main-element')) {
                if (showTableMainElement) {
                    row.style.display = '';
                } else {
                    row.style.display = 'none';
                }
            } else {
                if (shouldHide) {
                    row.style.display = 'none';
                } else {
                    row.style.display = '';
                }
            }
        });
    });

    }




    // Add an event listener to main elements (class "table-main-element")
    const mainElements = document.querySelectorAll('.table-main-element');
    mainElements.forEach(mainElement => {
        mainElement.addEventListener('click', () => {
            // Find the corresponding sub-elements
            const subElements = getSubElements(mainElement);

            // Toggle the visibility of sub-elements
            subElements.forEach(subElement => {
                subElement.classList.toggle('hidden');
            });
        });
    });

// Function to get the corresponding sub-elements for a main element
    function getSubElements(mainElement) {
        const subElements = [];
        let nextSibling = mainElement.nextElementSibling;

        // Find the adjacent sub-elements
        while (nextSibling && nextSibling.classList.contains('sub-element')) {
            subElements.push(nextSibling);
            nextSibling = nextSibling.nextElementSibling;
        }

        return subElements;
    }


   //Highlighting of table cells
    // Get all elements with the class 'highlightable'
    const highlightableElements = document.querySelectorAll('.highlightable');

// Add a click event listener to each element
    highlightableElements.forEach(element => {
        element.addEventListener('click', () => {
            // Remove the 'highlighted' class from all elements with the class 'highlightable'
            highlightableElements.forEach(el => {
                el.classList.remove('highlighted');
            });

            // Add the 'highlighted' class to the clicked element
            element.classList.add('highlighted');
        });
    });

});



