var $titleCircle = $('article.post-type-service .article-body .title-and-text h2 span').first();
var $title = $('article.post-type-service .article-body .title-and-text .circle-h2').first();
var $articleBody = $('article.post-type-service .article-body');
var $articleTitle = $('article.post-type-service .article-content .article-h1');
var $articleTitleCircle = $('article.post-type-service .article-content .article-h1 span.title-circle');

if ($title.length > 0) {
    if ($(window).width() >= 1024) {
        $(window).scroll(function () {

            if ($(window).scrollTop() >= ($articleTitle.offset().top - 16)) {
                $articleTitleCircle.css({
                    'transform': 'translateY(' + ($(this).scrollTop() - $articleTitle.offset().top + 16) + 'px)'
                });
                if ($(window).scrollTop() >= ($title.offset().top - 48)) {
                    $articleTitleCircle.css({
                        'width': '32px',
                        'min-width': '32px',
                        'height': '32px',
                        'left': '-3.5rem'
                    });
                }
            }
            else {
                $articleTitleCircle.css({
                    'transform': 'translateY(0)',
                    'width': '40px',
                    'min-width': '40px',
                    'height': '40px',
                    'left': '-3.75rem'
                });
            }

            if (($(window).scrollTop() >= ($title.offset().top - 24)) && ($(window).scrollTop() < ($articleBody.offset().top + $articleBody.outerHeight() + 20))) {
                $titleCircle.css({
                    'transform': 'translateY(' + ($(this).scrollTop() - $title.offset().top + 24) + 'px)'
                });
            }
            else {
                $titleCircle.css({
                    'transform': 'translateY(0)'
                });
            }
        });
    }
}
var $linksContainer = $('article.post-type-service .links-wrapper .links');

function checkLinksWidth() {
    var $linksContainer = $('article.post-type-service .links-wrapper .links');
    var $linksWrapper = $('article.post-type-service .links-wrapper');
    var $registerButton = $('article.post-type-service .links-wrapper .buttons .round-button');
    var $registerButtonWidth;
    var $loginButton = $('article.post-type-service .links-wrapper .buttons .login');
    var $loginButtonWidth;
    if ($registerButton.length > 0) {
        $registerButtonWidth = $registerButton.width();
    }
    else {
        $registerButtonWidth = 0;
    }
    if ($loginButton.length > 0) {
        $loginButtonWidth = $loginButton.width();
    }
    else {
        $loginButtonWidth = 0;
    }

    if (($(window).width() >= 1024) && ((($linksContainer.prop('scrollWidth') + 80) > ($linksWrapper.width() - $registerButtonWidth - $loginButtonWidth - 24 - 80))) && ($linksContainer.width() != 0)) {
        $linksContainer.parent('.links-wrapper').addClass('column');
    }
}

if ($linksContainer) {
    checkLinksWidth();
}

$(window).on('resize', function () {
    $linksContainer.parent('.links-wrapper').removeClass('column');
    checkLinksWidth();
});


var $eraAcute = $('body.era-acute article.post-type-service .article-content .article-h1 p');
var $magnetJqs = $('body.magnet-jqs article.post-type-service .article-content .article-h1 p');

var $eraAcuteTitle = $eraAcute.text();
var $magnetJqsTitle = $magnetJqs.text();
var $eraSplitted = $eraAcuteTitle.split(' ');
var $magnetSplitted = $magnetJqsTitle.split(' ');

$eraAcute.html($eraSplitted[0] + '<span>' + $eraSplitted[1] + '</span>');
$eraAcute.find('span').css({
    'color': '#D0CFCD'
});

$magnetJqs.html($magnetSplitted[0] + '<span>' + $magnetSplitted[1] + '</span>');
$magnetJqs.find('span').css({
    'color': '#D0CFCD'
});

var $operatorsLinks = $('header .services .operators-services-links a');
$operatorsLinks.sort(function (a, b) {
    if (a.textContent < b.textContent) {
        return -1;
    } else {
        return 1;
    }
}).appendTo($('header .services .operators-services-links'));


var $suppliersLinks = $('header .services .suppliers-services-links a');
$suppliersLinks.sort(function (a, b) {
    if (a.textContent < b.textContent) {
        return -1;
    } else {
        return 1;
    }
}).appendTo($('header .services .suppliers-services-links'));

var $allLinks = $('header .services .all-services a');
    $allLinks.sort(function(a, b) {
        if (a.textContent < b.textContent) {
          return -1;
        } else {
          return 1;
        }
      }).appendTo($('header .services .all-services'));
