var $figure = $('article.post-type-service .article-content .article-body figure');

if ($figure.length > 0 && $(window).width() >= 1024) {
    var $textWrappers = $('article.post-type-service .article-content .article-body .title-and-text .text-wrapper');
    var $textWrapper;
    $textWrappers.each(function () {
        if ($(this).outerHeight() != 0) {
            $textWrapper = $(this).first();
        }
    })
    
    var $textWrapperOffset = $textWrapper.offset().left;
    $figure.css({
        'margin-left': $textWrapperOffset - 80
    });
    
    $(window).on('resize', function () {
        var $textWrapperOffset = $textWrapper.offset().left;
        $figure.css({
            'margin-left': $textWrapperOffset - 80
        });
    });
}