var $showMore = $('.support .support-past-issues .link-button.more');
var $showLess = $('.support .support-past-issues .link-button.less');
var $links = $('.support .support-past-issues .links a');
var $projectLinkColor = $('article.post-type-service').data('secondary-color');
var $mailContainer = $('.support .support-contact .text-content .contact-links .mail-wrapper');
var $contactsContainer = $('.support .support-contact .text-content .contact-links');
var $phoneContainer = $('.support .support-contact .text-content .contact-links .phone-wrapper');


document.styleSheets[0].addRule('a.styled-link:after', 'border-bottom: 2px solid ' + $projectLinkColor);
document.styleSheets[0].addRule('article .title-and-text .links a.styled-link:after', 'border-bottom: 2px solid ' + $projectLinkColor);
document.styleSheets[0].addRule('.help-centre a.styled-link:after', 'border-bottom: 2px solid #38D430');
$links.attr('data-color', $projectLinkColor);
$('article .title-and-text .links a.styled-link:after').attr('data-color', $projectLinkColor);
$('.help-centre .support a.styled-link:after').attr('data-color', '#38D430');

$showMore.click(function (e) {
    e.preventDefault();
    $links.addClass('show');
    $(this).removeClass('show');
    $(this).parent().children('.less').addClass('show');
});

$showLess.click(function (e) {
    e.preventDefault();
    $links.removeClass('show');
    for (var i = 0; i < 3; i++) {
        $links.eq(i).addClass('show');
    }
    $(this).removeClass('show');
    $(this).parent().children('.more').addClass('show');
});

if (($(window).width() >= 1024) && ($contactsContainer.width() < ($mailContainer.prop('scrollWidth') +  $phoneContainer.width() + 41)) && (($mailContainer.prop('scrollWidth')) > $mailContainer.width()) && ($mailContainer.width() != 0)) {
    $contactsContainer.addClass('column');
}

$(window).on('resize', function () {
    if (($(window).width() >= 1024) && ($contactsContainer.width() < ($mailContainer.prop('scrollWidth') +  $phoneContainer.width() + 41)) && (($mailContainer.prop('scrollWidth')) > $mailContainer.width()) && ($mailContainer.width() != 0)) {
        $contactsContainer.addClass('column');
    }
    else {
        $contactsContainer.removeClass('column');
        if ($contactsContainer.width() < ($mailContainer.prop('scrollWidth') +  $phoneContainer.width() + 41)) {
            $contactsContainer.addClass('column');
        }
    }
});