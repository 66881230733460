var $services = $('.help-hero .services-wrapper a');

$services.each(function() {
    var $bgColor = $(this).data('bg-color');
    var $textColor = $(this).data('text-color');
    $(this).hover(function() {
        $(this).css({
            'background-color': $bgColor,
            'color': $textColor
        });
    }, function() {
        $(this).css({
            'background-color': '#fff',
            'color': '#000'
        });
    });
});