var $getStartedButton = $('a[href^="#get-started"], a[href^="#sign-up"]');
var $getStartedForm = $('#get-started-form');
var $popup = $('.get-started-popup');
var $overlay = $('.get-started-overlay');
$body = $('body');
var $closeButton = $('.get-started-popup .button-icon');

$getStartedButton.click(function (e) {
    e.preventDefault();
    showGetStartedPopup();
});

function showGetStartedPopup() {
    if ($popup.hasClass('show')) {
        closePopup();
    }
    else {
        $popup.css({
            'display': 'flex'
        });
        $overlay.css({
            'display': 'flex'
        });
        setTimeout(() => {
            $popup.addClass('show');
            $overlay.addClass('show');
            $body.addClass('no-scroll');
        }, 200);
    }
}

$getStartedForm.submit(function (e) {
    e.preventDefault();

    var $searchInput = $(this).find('input#get-started-searchstring');
    var $countrySelect = $(this).find('select#get-started-country');
    if(window.location.href.includes("staging")) {
        window.location = '/register-test/#/?s=' + $searchInput.val() + '&c=' + $countrySelect.val();
    } else {
        window.location = '/register/#/?s=' + $searchInput.val() + '&c=' + $countrySelect.val();
    }
});

$closeButton.click(function () {
    closePopup();
});

function closePopup() {
    $popup.removeClass('show');
    $overlay.removeClass('show');
    $body.removeClass('no-scroll');
    setTimeout(() => {
        $popup.css({
            'display': 'none'
        });
        $overlay.css({
            'display': 'none'
        });
    }, 200);
}

if (window.location.hash === '#sign-up') {
    showGetStartedPopup();
}
