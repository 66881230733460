import './_body-view-height'

import './_menu.js';
import './_hero.js';
import './_accordion.js';
import './_custom-select.js';
import './_scroll-to.js';
import './_phases.js';
import './_latest-updates.js';
import './_menu-on-scroll.js';
import './_related-services.js';
import './_support.js';

import './_text-columns.js';
import './_login-popup.js';
import './_services.js';
import './_get-started-popup.js';
import './_title-and-text.js';
import './_links-and-button.js';
import './_help-hero.js';
import './_suppliers-services.js';
import './_figure.js';

if (window.location.hash) {
    var target = window.location.hash.replace('#', '');
    $('header .main-menu-mobile').removeClass('open');
    setTimeout(function () {
        $('html,body').animate({
            scrollTop: $("#" + target).offset().top - 64
        },300);
    }, 900)
}
import './_staging-banner.js';

import './_newsletter.js';

import './_resource-guide.js';