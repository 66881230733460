var $linksContainer = $('.links-and-button .links');

if (($(window).width() >= 1024) && (($linksContainer.prop('scrollWidth') - 66) > $linksContainer.width()) && ($linksContainer.width() != 0)) {
    $linksContainer.parent().addClass('column');
} 
var $linksContainer = $('.links-and-button .links');

function checkLinksWidth() {
    var $linksContainer = $('.links-and-button .links');
    var $linksWrapper = $('.links-and-button');
    var $registerButton = $('.links-and-button .buttons .round-button');
    var $registerButtonWidth;
    var $loginButton = $('.links-and-button .buttons .login');
    var $loginButtonWidth;
    if($registerButton.length > 0) {
        $registerButtonWidth = $registerButton.width();
    }
    else {
        $registerButtonWidth = 0;
    }
    if($loginButton.length > 0) {
        $loginButtonWidth = $loginButton.width();
    }
    else {
        $loginButtonWidth = 0;
    }

    if (($(window).width() >= 1024) && ((($linksContainer.prop('scrollWidth') + 80) > ($linksWrapper.width() - $registerButtonWidth - $loginButtonWidth - 24 - 80))) && ($linksContainer.width() != 0)) {
        $linksContainer.parent().addClass('column');
    }     
}

if($linksContainer) {
    checkLinksWidth();
}

$(window).on('resize', function () {
    $linksContainer.parent().removeClass('column');
    checkLinksWidth();
});