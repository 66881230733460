var $viewButton = $('.latest-updates .wrapper .round-button');
var $postsLength = $('.latest-updates .wrapper .posts .tease').length;
var $post = $('.latest-updates .wrapper .posts article');
var $excerptMoreButton = $('.latest-updates .wrapper .posts article .excerpt-only .post-link');

if ($(window).width() < 768) {
    for (var i = 0; i < 3; i++) {
        $post.eq(i).addClass('show');
    }
    if ($postsLength > 3) {
        $('.latest-updates .wrapper .more').addClass('show');
    }
    else {
        $('.latest-updates .wrapper .posts').css({
            'border-bottom': 'none'
        })
    }
}
else {
    for (var i = 0; i < 6; i++) {
        $post.eq(i).addClass('show');
    }
    if ($postsLength > 6) {
        $('.latest-updates .wrapper .more').addClass('show');
    }
    else {
        $('.latest-updates .wrapper .posts').css({
            'border-bottom': 'none'
        })
    }
}


$viewButton.click(function (e) {
    e.preventDefault();
    if ($(window).width() < 768) {
        if ($(this).hasClass('more')) {
            var $postsShown = $('.tease.show').length;
            console.log($postsShown);
            for (var i = 0; i < $postsShown + 3; i++) {
                if (!$(this).parent().children('.posts').children().eq(i).hasClass('show')) {
                    $(this).parent().children('.posts').children().eq(i).addClass('show');
                }
            }
            if (($postsLength - $postsShown) <= 3) {
                $(this).removeClass('show');
                $(this).parent().children('.less').addClass('show');
            }
        }
        else if ($(this).hasClass('less')) {
            $(this).removeClass('show');
            $(this).parent().children('.more').addClass('show');
            $(this).parent().children('.posts').children('.tease').removeClass('show');
            for (var i = 0; i < 3; i++) {
                $(this).parent().children('.posts').children('.tease').eq(i).addClass('show');
            }
        }
    }
    else {
        if ($(this).hasClass('more')) {
            var $postsShown = $('.tease.show').length;
            for (var i = 0; i < $postsShown + 6; i++) {
                if (!$(this).parent().children('.posts').children().eq(i).hasClass('show')) {
                    $(this).parent().children('.posts').children().eq(i).addClass('show');
                }
            }
            if (($postsLength - $postsShown) <= 6) {
                $(this).removeClass('show');
                $(this).parent().children('.less').addClass('show');
            }
        }
        else if ($(this).hasClass('less')) {
            $(this).removeClass('show');
            $(this).parent().children('.more').addClass('show');
            $(this).parent().children('.posts').children('.tease').removeClass('show');
            for (var i = 0; i < 6; i++) {
                $(this).parent().children('.posts').children('.tease').eq(i).addClass('show');
            }
        }
    }
});

$excerptMoreButton.click(function() {
    $(this).parent().find('.excerpt-preview').removeClass('show');
    $(this).parent().find('.excerpt-more').addClass('show');
    $(this).hide();
});