var $menuIcon = $('header .main-menu-mobile .mobile-menu-button');
var $mobileMenu = $('header .main-menu-mobile');
var $serviceButton = $('header .main-menu-desktop .menu-links .services');
var $menuContent = $('header .main-menu-mobile .mobile-menu-content');
var $headerHeight = $('header').outerHeight();
var $loginPopup = $('.login-popup');
var $overlay = $('header .main-menu-mobile .services-menu-overlay');
var $adminBar = $('body.admin-bar');
var $logoOverlay = $('.site-logo .overlay');
var $seeAllButton = $('header .main-menu-mobile .mobile-menu-content .services .operators-links a.see-all');
var $allServices = $('header .main-menu-mobile .mobile-menu-content .services .all-services');
var $servicesOverlay = $('header .main-menu-mobile .mobile-menu-content .services .all-services .overlay');
var $backButton = $('header .main-menu-mobile .mobile-menu-content .services .all-services a.back-btn');
var $services = $('header .main-menu-mobile .mobile-menu-content .services a:not(.square-button):not(.see-all):not(.back-btn):not(.menu-links a)');

$services.each(function () {
    var $bgColor = $(this).data('bg-color');
    var $textColor = $(this).data('text-color');
    $(this).hover(function () {
        $(this).css({
            'background-color': $bgColor,
            'color': $textColor
        });
    }, function () {
        $(this).css({
            'background-color': '#FFFFFF',
            'color': '#000'
        });
    });
});

if ($(window).width() < 768) {
    setTimeout(function () {
        $menuContent.addClass('content-transition');
    }, 500);
}

$menuIcon.click(function () {
    if ($(this).hasClass('open')) {
        $(this).removeClass('open');
        $(this).children('p').html('Menu');
        $logoOverlay.removeClass('show');
        $mobileMenu.removeClass('menu-open');
        setTimeout(function () {
            $mobileMenu.removeClass('show');
        }, 500);
        if ($loginPopup.hasClass('show')) {
            $loginPopup.removeClass('show');
        }
        $('body').removeClass('no-scroll');
    }
    else {
        $(this).children('p').html('Close');
        $(this).addClass('open');
        $mobileMenu.addClass('show');
        setTimeout(function () {
            $mobileMenu.addClass('menu-open');
        }, 50);
        setTimeout(function () {
            $logoOverlay.addClass('show');
        }, 50);
        $('body').addClass('no-scroll');
    }
});

$serviceButton.click(function () {
    if ($(this).hasClass('open')) {
        hideMenu();
    }
    else {
        $(this).addClass('open');
        setTimeout(() => {
            $mobileMenu.addClass('menu-open');
        }, 200);
        if ($(window).width() >= 1024) {
            $overlay.css({
                'display': 'flex'
            });
            setTimeout(function () {
                $logoOverlay.addClass('show');
            }, 200);
            setTimeout(() => {
                $overlay.addClass('show');
            }, 200);
        }
        if($('header .services-menu-overlay').outerWidth() + $menuContent.outerWidth() > 1920) {
            $menuContent.children('.services').css({
                'width': ($('header .main-menu-desktop').innerWidth()/2) - (8*16),
                'margin-left': $('header .main-menu-desktop').offset().left
            })
        }
        $('header').addClass('show-services');
        $menuContent.css({
            'display': 'grid'
        });
    }
});

$seeAllButton.click(function (e) {
    e.preventDefault();
    $allServices.css({
        'display': 'flex'
    });
    $servicesOverlay.css({
        'display': 'flex'
    });
    setTimeout(() => {
        $allServices.addClass('show');
        $servicesOverlay.addClass('show');
    }, 200);
});

$backButton.click(function (e) {
    e.preventDefault();
    $allServices.removeClass('show');
    $servicesOverlay.removeClass('show');
    $allServices.css({
        'display': 'none'
    });
    $servicesOverlay.css({
        'display': 'none'
    });
});

function hideMenu() {
    $serviceButton.removeClass('open');
    $logoOverlay.removeClass('show');
    $mobileMenu.removeClass('menu-open');
    setTimeout(function () {
        $('header').removeClass('show-services');
    }, 700);
    closePopup();

}

function closePopup() {
    setTimeout(() => {
        $overlay.removeClass('show');
    }, 200);
    setTimeout(() => {
        $overlay.css({
            'display': 'none'
        });
    }, 100);
    setTimeout(() => {
        $menuContent.css({
            'display': 'none'
        });
    }, 200);
}

$(document).click(function (event) {
    var $target = $(event.target);
    if ($target.is($overlay) || $target.is($('ul li a'))) {
        hideMenu();
    }
});

$(window).on('resize', function () {
    if($('header .services-menu-overlay').outerWidth() + $menuContent.outerWidth() > 1920) {
        $menuContent.children('.services').css({
            'width': ($('header .main-menu-desktop').innerWidth()/2) - (8*16),
            'margin-left': $('header .main-menu-desktop').offset().left
        })
    }
});