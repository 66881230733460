var $button = $('.text-columns .columns.with-button .round-button');
var $textColumns = $('.text-columns .columns.with-button .text-content');

function displayColumns() {
    if ($button.length > 0) {
        for (var i = 0; i < 3; i++) {
            $textColumns.eq(i).addClass('show');
        }
    }
}
displayColumns();

$button.click(function (e) {
    e.preventDefault();
    var $textColumnsDisplayed = $('.text-columns .columns.with-button .text-content.show');
    var $textColumnsNotDisplayed = $('.text-columns .columns.with-button .text-content:not(.show)');
    if ($(this).hasClass('more')) {
        if ($textColumnsNotDisplayed.length > 0) {
            for (var i = 0; i < 3; i++) {
                $textColumnsNotDisplayed.eq(i).addClass('show');
            }
            if($textColumnsNotDisplayed.length <= 3) {
                $(this).removeClass('show');
                $(this).parent().children('.less').addClass('show');
            }
        }
        else {
            $(this).removeClass('show');
            $(this).parent().children('.less').addClass('show');
        }
    }
    else if ($(this).hasClass('less')) {
        $(this).removeClass('show');
        $(this).parent().children('.more').addClass('show');
        $textColumns.removeClass('show');
        displayColumns();
    }
});