window.addEventListener("scroll", hideMenu)
let isChanging = false;
var lastScrollTop = 0;

function hideMenu() {
    var $banner = $('.staging-banner');
    var st = window.pageYOffset || document.documentElement.scrollTop;
    if (st > lastScrollTop) {
        if (window.pageYOffset >= 1) {
            setTimeout(function () {
                $banner.css({
                    'opacity': 0
                });
            }, 100);
        }
        else {
            $banner.css({
                'opacity': 1
            });
        }
    } else {
        $banner.css({
            'opacity': 1
        });
    }
    lastScrollTop = st <= 0 ? 0 : st;
}