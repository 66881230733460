var $loginPopup = $('.login-popup');
var $loginButton = $('.login-button');
var $closeButton = $('.login-popup .button-icon');
var $body = $('body');
var $headerHeight = $('header').outerHeight();
var $overlay = $('.get-started-overlay');
var $services = $('.login-popup .services a');

$services.each(function() {
    var $bgColor = $(this).data('bg-color');
    var $textColor = $(this).data('text-color');
    $(this).hover(function() {
        $(this).css({
            'background-color': $bgColor,
            'color': $textColor
        });
    }, function() {
        $(this).css({
            'background-color': '#fff',
            'color': '#000'
        });
    });
});

$loginButton.click(function (e) {
    e.preventDefault();
    if ($loginPopup.hasClass('show')) {
        closePopup();
    }
    else {
        $loginPopup.css({
            'display': 'flex'
        });
        if ($(window).width() >= 768) {
            $overlay.css({
                'display': 'flex'
            });
        }
        setTimeout(() => {
            $loginPopup.addClass('show');
            $overlay.addClass('show');
            $body.addClass('no-scroll');
        }, 200);

    }
});

$closeButton.click(function () {
    closePopup();
});

function closePopup() {
    $loginPopup.removeClass('show');
    $overlay.removeClass('show');
    $body.removeClass('no-scroll');
    setTimeout(() => {
        $loginPopup.css({
            'display': 'none'
        });
        $overlay.css({
            'display': 'none'
        });
    }, 200);
}


var $allLinks = $('.login-popup .services a');
    $allLinks.sort(function(a, b) {
        if (a.textContent < b.textContent) {
          return -1;
        } else {
          return 1;
        }
      }).appendTo($('.login-popup .services'));
