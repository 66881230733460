var $serviceColor = $('article.post-type-service').data('main-color');
var $serviceTextColor = $('article.post-type-service').data('text-color');
var $relatedServices = $('.related-services .wrapper .service');
$relatedServices.hover(function() {
    $(this).children('a').css({
        'background-color': $serviceColor,
        'color': $serviceTextColor
    });
    $(this).children('a').children('p').children('svg').children('path').css({
        'fill': $serviceTextColor
    });
}, function() {
    $(this).children('a').css({
        'background-color': '#F2F2F1',
        'color': 'black'
    });
    $(this).children('a').children('p').children('svg').children('path').css({
        'fill': $serviceColor
    });
});