var $headerHeight = $('header').outerHeight();
var $hero = $('.hero.type-front-page');
$hero.css({
    'height': 'calc(100vh - ' + $headerHeight + 'px)',
    'height': 'calc(var(--vh, 1vh) * 100 - ' + $headerHeight + 'px)'
});
$('.hero .continue-indicator a').click(function (e) {
    e.preventDefault();

    var $this = $(this);
    var $parent = $this.parents('.hero');
    var $next = $parent.next('*');

    if ($next.length > 0) {
        $('html, body').animate({
            scrollTop: $next.offset().top - 50
        }, 400);
    }
});